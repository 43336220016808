import { useCallback, useContext, useEffect, useState } from 'react';
// import logo from './logo.svg';
// import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  // Route,
  Outlet,
  NavLink,
  useParams,
  useNavigate,
  Navigate,
} from "react-router-dom";
import './App.css';

import debounce from 'lodash.debounce';
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useQuery, ApolloLink, useMutation, HttpLink } from '@apollo/client';

import {
  FRAGMENT_ADRE_ALL_FIELDS,
  FRAGMENT_AGEN_ALL_FIELDS,
  FRAGMENT_OFFR_ALL_FIELDS,
  INNER_FRAGMENT_OFFRMULT_UNION_MATCH,
} from './Queries'
import { useLocalStorage } from './Hooks';


const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${JSON.parse(token)}` : '',
    }
  })
  return forward(operation)
})


const GRAPHQL_URI = (window.location.host === 'royalmanor-esimmo.masro.be')
  ? 'https://royalmanor-esimmo.masro.be/graphql'
  : 'http://localhost:8000/graphql'
  ;

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([ authLink, new HttpLink({ uri: GRAPHQL_URI }) ])
});

const Navbar = () => {
  const activeTab = 'addr';
  const [storedToken, setStoredToken] = useLocalStorage('token')

  return (
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a class="navbar-brand" href="/">DATA VIEWER</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mr-auto">
          <li className={"nav-item"} >
            <NavLink className="nav-link" to="/ADREs/">Carnet d'adresses</NavLink>
          </li>
          <li className={"nav-item"}>
            <NavLink className="nav-link" to="/OFFRs/">Offres</NavLink>
          </li>
        </ul>
        <ul class="nav navbar-nav">
            <li class="nav-item">
                <a class="nav-link" href="#" onClick={() => {
                  setStoredToken(null)
                  window.location.href = "/";                  
                }}>Logout</a>
            </li>
        </ul>
      </div>
    </nav>
  )
}

const NonNullResults = ({ results, skipKeys }) => {
  skipKeys = skipKeys || ['__typename'];
  return (
    <>
      <table className='table table-sm table-bordered'>
        <thead className='thead-dark'>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {results.map(result => (
            <>
              {Object.keys(result)
               .filter(key => result[key] !== null)
               .filter(key => result[key] !== '0')
               .filter(key => result[key] != '0.00')
               .filter(key => skipKeys.indexOf(key) === -1)
               .filter(key => !key.startsWith('TABL'))
               .filter(key => {
                 if (typeof result[key] === 'string') {
                   return result[key].trim() !== ''
                 }
               })
               .map(key => (
                <tr>
                  <td><strong>{key}</strong></td>
                  <td>{result[key]}</td>
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </table>
    </>
  )
}

const OffrSearchComponent = () => {
  const skip = 0;
  const [queryString, setQueryString] = useState('');

  const navigate = useNavigate();
  const handleOnClick = useCallback(id => {
    navigate(`/OFFR/${id}`, { replace: false })
  }, [navigate])

  const { loading, error, data, refetch } = useQuery(gql`
    ${FRAGMENT_OFFR_ALL_FIELDS}
    query SearchOffersQuery ($q: String!, $skip: Int!) {
      search_offr(q: $q, skip: $skip, count: 35) {
        count
        results {
          ...OffrAllFields
          offradre_set {
            contact {
              REFE
            }
            TYPE
            NUME
            DEN1
            DEN2
            DEN3
          }
          offrphot_set {
            PATH
            PHOT_COMM
          }
        }
      }
    }
  `, { variables: {
    q: '',
    skip,
    }
  });

  const onSearch = async (queryString) => {
    await refetch({ q: queryString });
  }

  const deboucedOnSearch = useCallback(debounce(onSearch, 500), [])

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const searchResults = data?.search_offr?.results || []

  // const skipKeys = [
  //   'IDEN',
  //   '__typename',
  //   'OFFR',
  //   'ADRE',
  //   'ADR1',
  //   'ADRN',
  //   'OFFR_NL',
  //   'OFFR_EN',
  //   'CREA_UTIL',
  //   'MODI_UTIL',
  //   'ACTI_UTIL',
  //   'ACTU_UTIL',
  //   'TRAN_DATE',
  // ]

  return (
    <>
      <input class="form-control" autoFocus type="text" value={queryString} onChange={e => {
        setQueryString(e.target.value);
        deboucedOnSearch(e.target.value);
      }}/>
      <hr />

      {loading
        ? <p>Loading...</p>
        : (error)
        ? <p>Error :(</p>
        : (
          <>
            <h6>results found: {data?.search_offr?.count}</h6>
            <table className='table table-sm table-bordered'>
              <thead className='thead-dark'>
                <tr>
                  <th>Ref.</th>
                  <th>Type</th>
                  <th>Commune</th>
                  <th style={{textAlign: "right"}}>Prix</th>
                  <th>Classement</th>
                  <th></th>
                  <th>Contact</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((result, i) => (
                  <tr key={i} onClick={e => handleOnClick(result.IDEN)} style={{ cursor: 'pointer' }}>
                    <td>{result.REFE}</td>
                    <td>{result.TYPE}</td>
                    <td>{result.LOCA}</td>
                    <td style={{textAlign: "right"}}>{result.PRIX} €</td>
                    <td>{result.CLAS}</td>
                    <td>{result.offradre_set.length > 0 && (<>{result.offradre_set[0].TYPE}</>)}</td>
                    <td>{result.offradre_set.length > 0 && (<>{result.offradre_set[0].contact?.REFE}</>)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
    </>
  );
}

const AddrSearchComponent = () => {
  const skip = 0;
  const [queryString, setQueryString] = useState('');

  const navigate = useNavigate()
  const handleOnClick = useCallback(id => {
    navigate(`/ADRE/${id}`, { replace: false });
  }, [navigate])

  const { loading, error, data, refetch } = useQuery(gql`
    ${FRAGMENT_ADRE_ALL_FIELDS}
    query SearchAddressBookQuery ($q: String!, $skip: Int!) {
      search_adre(q: $q, skip: $skip, count: 25) {
        count
        results {
          ...AdreAllFields
          offradre_set {
            TYPE
            NUME
            offre {
              IDEN
              ADR1
              REFE
            }
          }
        }
      }
    }
  `, { variables: {
    q: '',
    skip,
    }
  });

  const onSearch = async (queryString) => {
    await refetch({ q: queryString });
  }

  const deboucedOnSearch = useCallback(debounce(onSearch, 500), [])

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const searchResults = data?.search_adre?.results || []
  // console.log(data);
  // const searchResults = []

  return (
    <>
      <input class="form-control" autoFocus type="text" value={queryString} onChange={e => {
        setQueryString(e.target.value);
        deboucedOnSearch(e.target.value);
      }}/>
      
      <br />

      {loading
        ? <p>Loading...</p>
        : (error)
        ? <p>Error :(</p>
        : (<>

          <h6>results found: {data?.search_adre?.count}</h6>

          <table className='table table-sm table-bordered'>
            <thead className='thead-dark'>
              <tr>
                <th style={{ whiteSpace: 'nowrap' }}>Ref 1</th>
                <th style={{ whiteSpace: 'nowrap' }}>Ref 2</th>
                <th>Acti</th>
                <th>Nom 1</th>
                <th>Nom 2</th>
                <th>Telephone</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map(r => (
                <tr style={{ cursor: 'pointer' }} onClick={() => handleOnClick(r.ContactID)}>
                  <td>{r.REFE}</td>
                  <td>{r.REFE2}</td>
                  <td>{r.ACTI}</td>
                  <td>{r.NOM1}</td>
                  <td>{r.NOM2}</td>
                  <td>{r.TELE1}</td>
                </tr>
              ))}
            </tbody>
          </table>
      </>)}
    </>
  );
}

const OffrMultLookupComponent = ({ offrmult_set }) => {
  const { data, loading, error } = useQuery(gql`
    query OffrMultLookup ( $lookup: [TablLookupInputType] ) {
      offrmult_lookup ( lookup: $lookup ) {
        ${INNER_FRAGMENT_OFFRMULT_UNION_MATCH}
      }
    }
  `, {
    variables: {
      lookup: offrmult_set,
    }
  })
  if (loading) return 'loading'
  if (error) return 'error'
  if (data.offrmult_lookup.length === 0) return '';
  return (
    <>
      <table className="table table-sm table-bordered">
        <thead className='thead-dark'>
          <tr>
            <th>FR</th>
            <th>NL</th>
            <th>IWEB</th>
          </tr>
        </thead>
        <tbody>
          {data.offrmult_lookup.map(om => (
            <tr>
              <td>{om.LIBE_FR}</td>
              <td>{om.LIBE_NL}</td>
              <td>{om.IWEB}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}


const GenericAgenListingComponent = ({ contactID, offerID, demandeID }) => {
  const count = 10
  const [ skip, setSkip ] = useState(0)
  const [ queryString, setQueryString ] = useState('')
  const { data, error, loading, refetch } = useQuery(gql`
    ${FRAGMENT_AGEN_ALL_FIELDS}
    query GenericAgenListing ( $q: String!, $skip: Int!, $count: Int!, $contactID: String, $offerID: String, $demandeID: String ) {
      agen_lookup ( q: $q, skip: $skip, count: $count, contactID: $contactID, offerID: $offerID, demandeID: $demandeID ) {
        count
        results {
          ...AgenAllFields
          offre {
            REFE
            IDEN
          }
          contact {
            REFE
            ContactID
          }
        }
      }
    }
  `, {
    variables: {
      q: queryString,
      skip: 0,
      count: 10,
      contactID,
      offerID,
      demandeID,
    }
  })
  const onSearch = queryString => {
    refetch({ q: queryString });
  }

  useEffect(() => {
    refetch({ skip })
  }, [skip])

  const deboucedOnSearch = useCallback(debounce(onSearch, 500), [])

  if (loading) return 'loading'
  if (error) return 'error'

  const nextIsDisabled = (data.agen_lookup.results.length === 0);
  const matchedCount = data.agen_lookup.count;

  return (
    <>
      <Header>AGENDA / TASKS ( found {matchedCount } )</Header>

      <input
        className='form-control'
        onChange={e => deboucedOnSearch(e.target.value)}
        placeholder="Search..."
        />

      <table className='table table-sm table-bordered' style={{ marginTop: '1rem' }}>
        <thead className='thead-dark'>
          <tr>
            <th></th>
            <th>Date</th>
            <th>Heure</th>
            <th style={{ whiteSpace: 'nowrap' }}>Lib</th>
            {/**
            <th style={{ whiteSpace: 'nowrap' }}>Rem 2</th>
             */}
            <th>Offr</th>
            <th>Contact</th>
          </tr>
        </thead>

        <tbody>
          {data.agen_lookup.results.map(agen => (
            <tr>
              <td>
                  <NavLink to={`/AGEN/${agen.taskID}`}>DETAILS</NavLink>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>{agen.DATE ? agen.DATE.substr(0, 10) : ''}</td>
              <td style={{ whiteSpace: 'nowrap' }}>{agen.HEUR_DEB} - {agen.HEUR_FIN}</td>
              <td>{agen.LIBE}</td>
              {/**
              <td>{agen.REMA2}</td>
              */}
              <td style={{ whiteSpace: 'nowrap' }}>
                {agen?.offre?.IDEN && (
                  <NavLink to={`/OFFR/${agen.offre.IDEN}`}>{agen.offre.REFE}</NavLink>
                )}
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                {agen?.contact?.ContactID && (
                  <NavLink to={`/ADRE/${agen.contact.ContactID}`}>{agen.contact.REFE}</NavLink>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button className='btn btn-sm btn-secondary'
        onClick={() => setSkip(Math.max(0, skip - count))}
        disabled={skip === 0}
        >
        prev
      </button>
      <button className='btn btn-sm btn-secondary'
        onClick={() => setSkip(skip + count)}
        disabled={nextIsDisabled}
        >
        next
      </button>

      <br /><br />
    </>
  )
}



// const AgenListingComponent = ({ listing }) => {
//   return (
//     <>
//       <Header>AGENDA / TASKS</Header>
//       <table className='table table-sm table-bordered'>
//         <thead className='thead-dark'>
//           <tr>
//             <th>Date</th>
//             <th>Debut</th>
//             <th>Fin</th>
//             <th style={{ whiteSpace: 'nowrap' }}>Rem 1</th>
//             <th style={{ whiteSpace: 'nowrap' }}>Rem 2</th>
//             <th>Offr</th>
//             <th>Contact</th>
//           </tr>
//         </thead>
//         <tbody>
//           {listing.map(agen => (
//             <tr>
//               <td style={{ whiteSpace: 'nowrap' }}>{agen.DATE}</td>
//               <td style={{ whiteSpace: 'nowrap' }}>{agen.HEUR_DEB}</td>
//               <td style={{ whiteSpace: 'nowrap' }}>{agen.HEUR_FIN}</td>
//               <td>{agen.REMA}</td>
//               <td>{agen.REMA2}</td>
//               <td style={{ whiteSpace: 'nowrap' }}>
//                 {agen?.offre?.IDEN && (
//                   <NavLink to={`/OFFR/${agen.offre.IDEN}`}>{agen.offre.REFE}</NavLink>
//                 )}
//               </td>
//               <td style={{ whiteSpace: 'nowrap' }}>
//                 {agen?.contact?.ContactID && (
//                   <NavLink to={`/ADRE/${agen.contact.ContactID}`}>{agen.contact.REFE}</NavLink>
//                 )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </>
//   )
// }


const AdreComponent = () => {
  const { id } = useParams()
  const { data, loading, error } = useQuery(gql`
    ${FRAGMENT_ADRE_ALL_FIELDS}
    query GetAdre( $id: String! ) {
      adre( id: $id ) {
        ...AdreAllFields
        offradre_set {
          TYPE
          OFFR
          OFFRREFE
          offre {
            IDEN
          }
          contact {
            REFE
            ContactID
            TELE1
          }
        }
      }
    }
  `, {
    variables: {
      id
    }
  })
  if (loading) return 'loading'
  if (error) return 'error'
  const { adre } = data;
  return (
    <>
      <h2>{adre.REFE}</h2>

      <div className='row'>
        <div className='col-5'>
          <Header>DETAILS</Header>
          <NonNullResults results={[ adre ]} skipKeys={['__typename', 'ContactID']} />
        </div>
        <div className='col-7'>
          <GenericAgenListingComponent contactID={adre.ContactID} />

          {adre.offradre_set.length > 0 && (
            <>
              <Header>OFFERS:</Header>
              <table className='table table-sm table-bordered'>
                <thead className='thead-dark'>
                  <tr>
                    <th>Ref</th>
                    <th></th>
                    <th>Telephone</th>
                  </tr>
                </thead>
                <tbody>
                  {adre.offradre_set.map(o => (
                    <tr>
                      <td><NavLink to={`/OFFR/${o.offre.IDEN}`}>{o.OFFRREFE}</NavLink></td>
                      <td>{o.TYPE}</td>
                      <td>{o?.contact?.TELE1}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </>
  )
}


const Header = ({ children }) => <h5>{children}</h5>


const AgenComponent = () => {
  const { id } = useParams()
  const { data, loading, error } = useQuery(gql`
    ${FRAGMENT_AGEN_ALL_FIELDS}
    query GetAgen ( $id: String! ) {
      agen ( id: $id ) {
        ...AgenAllFields
      }
    }
  `, {
    variables: { id },
  })
  if (loading) return 'loading'
  if (error) return 'error'
  const { agen } = data;
  return (<>
    <NonNullResults results={[ agen ]} />
  </>)
}


const OffrComponent = () => {
  const { id } = useParams();
  const { data, loading, error } = useQuery(gql`
    ${FRAGMENT_OFFR_ALL_FIELDS}
    query GetOffr( $id: String! ) {
      offr( id: $id ) {
        ...OffrAllFields
        offrmult_set {
          TABL
          CODE
        }
        offrjoin_set {
          id
          PATH
        }
        offradre_set {
          TYPE
          OFFR
          OFFRREFE
          contact {
            REFE
            ContactID
            TELE1
          }
        }
        offrphot_set {
          PATH
          PHOT_COMM
        }
      }
    }
  `, {
    variables: {
      id,
    }
  })
  const navigate = useNavigate()
  if (loading) return 'Loading...'
  if (error) return 'Error :/'
  const { offr } = data;

  // strips the __typename from the input list:
  const offrMultLookup = offr.offrmult_set.map(({ TABL, CODE }) => ({ TABL, CODE }))

  // so ... some of the TABL* links are directly encoded in this table.
  // collect them and do a second "offrmult lookup" call:
  const otherMultLookup = Object.keys(offr)
    .filter(key => key.startsWith('TABL'))
    .filter(key => offr[key] !== null)
    .map(key => ({
      TABL: key,
      CODE: offr[key],
    }))

  return (
    <>
      
      {/**
      <button className="btn btn-secondary" onClick={() => navigate(-1)}>Go back</button>
      <br />
      */}

      <div className='row'>
        <div className='col-6'>
          <h2>{offr.REFE}</h2>
        </div>
        <div className='col-6 text-right'>
          {(offr.OFFR_FR || offr.OFFR_NL) && (
            <h2>{offr.OFFR_FR} / {offr.OFFR_NL}</h2>
          )}
        </div>
      </div>
      <hr />

      <div className='row'>
        <div className='col-5'>
          <Header>DETAILS:</Header>
          <NonNullResults results={[ offr ]} skipKeys={['IDEN', '__typename', 'ADRE', 'DIR']} />
        </div>
        <div className='col-7'>
          <GenericAgenListingComponent offerID={id} />

          {offr.offrphot_set.length > 0 && (
            <>
              <Header>PHOTOS</Header>
              <table className='table table-sm table-bordered'>
                <thead className='thead-dark'>
                  <tr>
                    <th>Path</th>
                    <th>Comm</th>
                  </tr>
                </thead>
                <tbody>
                  {offr.offrphot_set.map(photo => (
                    <tr>
                      <td>{offr.DIR}{photo.PATH}</td>
                      <td>{photo.PHOT_COMM}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {offr.offradre_set.length > 0 && (
            <>
              <Header>CONTACTS</Header>
              <table className='table table-sm table-bordered'>
                <thead className='thead-dark'>
                  <tr>
                    <th>Ref.</th>
                    <th>Type</th>
                    <th>Telephone</th>
                  </tr>
                </thead>
                <tbody>
                  {offr.offradre_set.map(o => (
                    <tr>
                      <td><NavLink to={`/ADRE/${o?.contact?.ContactID}`}>{o.contact.REFE}</NavLink></td>
                      <td>{o.TYPE}</td>
                      <td>{o?.contact?.TELE1}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {otherMultLookup.length !== 0 && (
            <>
              <Header>INTERNAL RELATED TABLES</Header>
              <OffrMultLookupComponent offrmult_set={otherMultLookup} />
            </>
          )}

          {offrMultLookup.length !== 0 && (
            <>
              <Header>EXTERNAL RELATED TABLES</Header>
              <OffrMultLookupComponent offrmult_set={offrMultLookup} />
            </>
          )}

          {offr.offrjoin_set.length > 0 && (
            <>
              <Header>RELATED FILES</Header>
              <table className='table table-sm table-bordered'>
                <thead className='thead-dark'>
                  <tr>
                    <th>Path</th>
                  </tr>
                </thead>
                <tbody>
                  {offr.offrjoin_set.map(pj => (
                    <tr>
                      <td>{pj.PATH}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </>
  )
}


const LoginForm = ({ setStoredToken }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [ TokenAuth ] = useMutation(gql`
    mutation TokenAuthMutation ( $username: String!, $password: String! ) {
      token_auth ( username: $username, password: $password ) {
        token
        payload
      }
    }
  `)

  const onLogin = async () => {
    const { data } = await TokenAuth({ variables: { username, password }})
    const { token_auth } = data
    const { token } = token_auth
    // localStorage.setItem('token', token)
    setStoredToken(token)
  }

  return (
    <div className='container'>
      <input placeholder='Username' className='form-control' onChange={e => setUsername(e.target.value)} />
      <input placeholder='Password' className='form-control' type='password' onChange={e => setPassword(e.target.value)} />
      <button className='btn btn-primary' onClick={onLogin}>login</button>
    </div>
  )
}


const Root = () => {
  const [storedToken, setStoredToken] = useLocalStorage('token', '')
  // const token = localStorage.getItem('token')
  const [verified, setVerified] = useState(false)
  const [ verifyToken ] = useMutation(gql`
    mutation VerifyTokenMutation ( $token: String! ) {
      verify_token ( token: $token ) {
        payload
      } 
    }
  `, {
    onCompleted: data => {
      console.log('VERIFED TOKEN')
      console.log(data);
      setVerified(true);
    },
    onError: () => {
      setStoredToken('')
      setVerified(false)
    }
  })
  useEffect(() => {
    console.log('storedToken:', storedToken)
    if (storedToken) {
      verifyToken({ variables: { token: storedToken }})
    } else {
      setVerified(false);
    }
  }, [storedToken, setVerified])

  if (verified) {
    return (
      <>
        <Navbar />
        <br />
        <div className="container-fluid">
          <Outlet />
        </div>
      </>
    )
  }

  return <LoginForm setStoredToken={setStoredToken} />
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: 'OFFRs/',
        element: <OffrSearchComponent />
      },
      {
        path: 'ADREs',
        element: <AddrSearchComponent />
      },
      {
        path: 'OFFR/:id',
        element: <OffrComponent />,
      },
      {
        path: 'ADRE/:id',
        element: <AdreComponent />,
      },
      {
        path: 'AGEN/:id',
        element: <AgenComponent />
      },
      {
        path: '*',
        element: <Navigate to="/OFFRs/" replace />,
      },
      {
        element: <Navigate to="/OFFRs/" replace />,
        index: true,
      },
    ],
  },
])

const App = () => {
  return (
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  );
};

export default App;
