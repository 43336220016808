import { gql } from "@apollo/client";

export const FRAGMENT_AGEN_ALL_FIELDS = gql`
  fragment AgenAllFields on AGENType {
    taskID
    CODE
    DATE
    JOUR
    HEUR
    HEUR_DEB
    HEUR_FIN
    HEUR_ALA
    FAIT
    UTIL
    OPER
    MONT
    PRIX
    ContactID
    ADRE
    ADREREFE
    OfferID
    OFFR
    OFFRREFE
    LIBE
    REMA
    REMA2
    CREA_DATE
    CREA_UTIL
    MODI_DATE
    MODI_UTIL
    TRAN_DATE
    TRAN_UTIL
    COUR_1
    COUR_2
    COUR_3
    COUR_4
    COUR_5
    COUR_6
    COUR_7
    COUR_8
    COUR_9
    COUR_10
    PRIX_ANNO
    SOUR
  }
`

export const FRAGMENT_ADRE_ALL_FIELDS = gql`
  fragment AdreAllFields on ADREType {
    ContactID
    CODE
    REFE
    REFE2
    NOM1
    NOM2
    TITR
    ADR1
    ADRN
    ADRP
    ADR2
    ADRE_TOT
    POST_TOT
    POST
    LOCA
    PAYS
    MAIL
    MAIL_COMM
    FAX
    FAX_COMM
    GSM
    TELE1
    TELE1_COMM
    TELE2
    TELE2_COMM
    TELE3
    TELE3_COMM
    TELE_1
    TELE_2
    TELE_3
    TELE_4
    TELE_5
    TELE_6
    TELE_7
    TELE_8
    TELE_9
    TELE_10
    TELE_11
    TELE_12
    TELE_13
    TELE_14
    TELE_15
    COMM_1
    COMM_2
    COMM_3
    COMM_4
    COMM_5
    COMM_6
    COMM_7
    COMM_8
    COMM_9
    COMM_10
    COMM_11
    COMM_12
    COMM_13
    COMM_14
    COMM_15
    RSMS
    URL
    URL_COMM
    ACTI
    MEDIA
    LANG
    RELA
    POLI
    REMA2
    NOMB_1
    NOMB_2
    NOMB_3
    NOMB_4
    NOMB_5
    NOMB_6
    NOMB_7
    NOMB_8
    NOMB_9
    NOMB_10
    TEXT_1
    TEXT_2
    TEXT_3
    TEXT_4
    TEXT_5
    TEXT_6
    TEXT_7
    TEXT_8
    TEXT_9
    TEXT_10
    GPS_X
    GPS_Y
    CREA_DATE
    CREA_HEUR
    CREA_UTIL
    MODI_DATE
    MODI_HEUR
    MODI_UTIL
    TRAN_DATE
    TRAN_HEUR
    TRAN_UTIL
    BANQ_1
    BANQ_2
    CTVA
    RECO
    NBRE
    REMA
    COUR_3
    COUR_1
    COUR_2
    COUR_7
    COUR_5
    COUR_6
    COUR_4
    COUR_8
    COUR_10
  }
`

export const FRAGMENT_OFFR_ALL_FIELDS = gql`
  fragment OffrAllFields on OFFRType {
    IDEN
    CODE
    DIR
    OFFR
    OFFR_FR
    OFFR_NL
    OFFR_EN
    ACTI
    REFE
    TYPE
    COMM
    PRIX
    CLAS
    ADR1
    ADR2
    ADRN
    ADRP
    ADRE_TOT
    POST
    LOCA
    POST_TOT
    REGION
    PAYS_CODE
    PAYS_FR
    PAYS_NL
    PLAN
    POSI_ETA
    POSI_TOT
    ETA_MIN
    ETA_MAX
    HAUT_COR_MIN
    HAUT_COR_MAX
    CHBR_NBR
    CHBR_COM
    BAIN_NBR
    BAIN_SDD
    BAIN_TOT
    BAIN_COM
    VOIT_NBR
    VOIT_COM
    BAIL
    DISP
    DISP_COM
    SUSP
    SUSP_COM
    TABLIMME
    TABLDEST
    TABLSTYL
    TABLTERR
    TABLTERD
    FACA_NBR
    FACA_AUT_1
    FACA_AUT_2
    FACA_AUT_3
    ORIE_IMM
    ORIE_COM
    ORIE_TER_01
    ORIE_TER_02
    MEUB_ON
    JARD_ON
    TERR_ON
    COUR_ON
    BURE_ON
    CAVE_ON
    GREN_ON
    ASCE_ON
    SUPE_TER_1
    SUPE_TER_2
    SUPE_TER_3
    SUPE_TER_TOT
    SUPE_JAR_1
    SUPE_JAR_2
    SUPE_JAR_3
    SUPE_JAR_TOT
    CADA_SUP
    FACA_LRG
    FACA_COM
    TERR_FAC
    PROF_LRG
    PROF_COM
    SURF_HAB
    SURF_BAT
    SURF_SEJ
    SURF_TER
    SURF_BUR
    SURF_COM
    BATI_SUR
    BATI_FAC
    BATI_PRO
    BATI_LAT
    BATI_REC
    BATI_COM_1
    BATI_COM_2
    BATI_COM_3
    BATI_COM_4
    BATI_COM_5
    GENE
    CUIS
    GELO
    CHAU
    RELI
    NIVE
    LOTI_ON
    ZONE_ON
    NATU_SOL
    NATU_SOU
    LOTI_AUT
    LOTI_REN
    BATI_AUT
    BATI_REN
    ANNE_CON
    TABLINTE
    TABLEXTE
    OEUV_AE
    OEUV_COM
    TOIT_AE
    TOIT_COM
    EXTE_AE
    EXTE_COM
    INTE_AE
    INTE_COM
    ELEC_AE
    ELEC_COM
    PLOM_AE
    PLOM_COM
    ETAN_AE
    ETAN_COM
    CHAU_AE
    CHAU_COM
    CUIS_AE
    CUIS_COM
    SANI_AE
    SANI_COM
    PRES_COM
    URBA_COM
    PREE_POS
    URBA_INF
    SOLS_ON
    CITE_MAZ_ON
    CITE_MAZ_CONF_ON
    CITE_MAZ_ATT_ON
    INST_ELEC_CONF_ON
    INST_ELEC_ATT_ON
    PRES_ENE_ON
    AFFE
    INON
    PROT_PATR_COM
    AUTRE_MES_COM
    ACTIV_INEX_COM
    TABLGEOG
    TABLQUAR
    SONO_AE
    SONO_COM
    COME_AE
    COME_COM
    ECOL_AE
    ECOL_COM
    COMU_AE
    COMU_COM
    EAUX_ON
    EAUX_COM
    EGOU_ON
    EGOU_COM
    GAZZ_ON
    GAZZ_COM
    ELCT_ON
    ELCT_COM
    DIST_ON
    DIST_COM
    TELE_ON
    TELE_COM
    PREC_IMM
    CADA_NON
    CADA_IND
    CADA_ENT
    CADA_DIV
    CADA_SEC
    CADA_NUM
    CADA_LOT
    SERV_COM
    CHAR_MON_1
    CHAR_MON_2
    CHAR_MON_3
    CHAR_MON_4
    CHAR_MON_5
    CHAR_MON_6
    CHAR_MON_7
    CHAR_MON_8
    CHAR_MON_9
    CHAR_MON_10
    CHAR_COM_1
    CHAR_COM_2
    CHAR_COM_3
    CHAR_COM_4
    CHAR_COM_5
    CHAR_COM_6
    CHAR_COM_7
    CHAR_COM_8
    CHAR_COM_9
    CHAR_COM_10
    CHAR_FOR_1
    CHAR_FOR_2
    CHAR_FOR_3
    CHAR_FOR_4
    CHAR_FOR_5
    CHAR_FOR_6
    CHAR_FOR_7
    CHAR_FOR_8
    CHAR_FOR_9
    CHAR_FOR_10
    CHAR_COM_11
    FRAI_MAX
    Total_Comment
    TotalFrais1
    TotalFrais2
    TotalPrixFrais1
    TotalPrixFrais2
    Garantie_montant
    CHAR_LOC
    CHAR_TOT
    CHAR_PRO
    CAUTION3
    CAUTION2
    FRAIS_ETATLIEUX
    FRAIS_REDUITS
    FRAI_RED
    GARA_COM
    RELO_REL
    RELO_POT
    DROI_SEL
    PRIX_UNI
    FRAI_MES
    MODA_VIS
    MODA_VIS2
    MODA_TRA
    DOCU_ON_1
    DOCU_ON_2
    DOCU_ON_3
    DOCU_ON_4
    DOCU_ON_5
    DOCU_ON_6
    DOCU_ON_7
    DOCU_ON_8
    DOCU_ON_9
    DOCU_ON_10
    DOCU_ON_11
    DOCU_ON_12
    DOCU_ON_13
    DOCU_ON_14
    DOCU_ON_15
    DOCU_ON_16
    DOCU_ON_17
    DOCU_ON_18
    DOCU_ON_19
    DOCU_ON_20
    DOCU_COM_1
    DOCU_COM_2
    DOCU_COM_3
    DOCU_COM_4
    DOCU_COM_5
    DOCU_COM_6
    DOCU_COM_7
    DOCU_COM_8
    DOCU_COM_9
    DOCU_COM_10
    DOCU_COM_11
    DOCU_COM_12
    DOCU_COM_13
    DOCU_COM_14
    DOCU_COM_15
    DOCU_COM_16
    DOCU_COM_17
    DOCU_COM_18
    DOCU_COM_19
    DOCU_COM_20
    MAND
    RENO
    SOUR
    GEST
    COMI
    RETR
    PRIX_AE
    PRIX_DEP
    PRIX_EST
    PRIX_NEG
    PRIX_DER
    CONF_ORI
    CONF_DES
    ADRE
    ADRE_REM
    ADRE_REM2
    INFO_CON
    INFO_CLA
    CREA_DATE
    CREA_UTIL
    MODI_DATE
    MODI_UTIL
    ACTU_DATE
    ACTU_UTIL
    ACTI_DATE
    ACTI_UTIL
    SURF_NBRE
    equipementint_fr
    equipementext_fr
    equipementint_nl
    equipementext_nl
    equipementint_en
    equipementext_en
    feuouvertfr
    buanderiefr
    aircofr
    doublevitrfr
    bihorairefr
    parlophonefr
    porteblindfr
    alarmefr
    feuouvertnl
    buanderienl
    airconl
    doublevitrnl
    bihorairenl
    parlophonenl
    porteblindnl
    alarmenl
    feuouverten
    buanderieen
    aircoen
    doublevitren
    bihoraireen
    parlophoneen
    porteblinden
    alarmeen
    ARCHI
    INGE_STAB
    COOR_SECU
    ETUD_SOL
    AMEN_CUIS
    AMEN_ABOR
    TEST_BLOW
    DELA_CONS
    FABR
    PERF
    VOIT_NBR2
    COUR_9
    COUR_x0028_9_x0029
    MAND_DEB
    DISP_DAT
    ANNE_REN
    TRAN_DATE
    CHBR_EXT
    COUR_10
    COUR_x0028_10_x0029
    PAYS_EN
    PAYS_DE
    TITR_FR
    TITR_NL
    TITR_EN
    TITR_DE
    TITR_XX
    FLASH_FR
    FLASH_NL
    FLASH_EN
    FLASH_DE
    FLASH_XX
    PRES_ENE
    URBA_INF_CAU
    URBA_INF_VER
    SOLS_COM
    MODA_TRA2
    BAIL_COM
    MAND_FIN
    PRES_CLAS
    PRES_ENE_NUME
    PRES_ENE_PEIL
    CHAR_INC_1
    CHAR_INC_2
    CHAR_INC_3
    CHAR_INC_4
    CHAR_INC_5
    CHAR_INC_6
    CHAR_INC_7
    CHAR_INC_8
    CHAR_INC_9
    CHAR_INC_10
    CLEF
    RETR_REM
    ADRE_OFF
    ADRE_WEB
    SUSP_DAT
    SURF_MAX
    CO2_EMIS
    DROI_MAX
    DROI_MIN
    HONO_MAX
    HONO_MIN
    DIVE_MAX
    DIVE_MIN
    FRAI_MIN
    PRIX_TOTA_MAX
    PRIX_TOTA_MIN
    RELO_ANN_REL
    RELO_ANN_POT
    REND_REL
    REND_POT
    REGI_TVA
    IMME_TVA
    QUOT_TER
    COUR_5
    COUR_x0028_5_x0029
    PRES_ENE_DATE
    PRES_ENE_PER
    VISI_VIRT
    VISI_VIRT1
    COUR_1
    COUR_x0028_1_x0029
    VOIT_EXT
    COUR_7
    COUR_x0028_7_x0029
    INST_ELEC_DATE
    COUR_4
    COUR_x0028_4_x0029
    BATI_DAT
    BAIN_EXT
    CITE_MAZ
    CITE_MAZ_DATE
    FLASH2_NL
    PLAN_EXPR
    AUTRE_MES
    ACTIV_INEX
    PLAN_ALIGN
    IMME_RAPP_COM
    FLASH2_FR
    PROT_PATR
    PRES_ENE_TOT
    COUR_3
    COUR_x0028_3_x0029
    NBR_LOG
    COUR_8
    COUR_x0028_8_x0029
    FLASH2_EN
    FLASH2_DE
    FLASH2_XX
    VISI_VIRT2
    COUR_6
    COUR_x0028_6_x0029
    BAIL_DEB
    BAIL_FIN
    CONS_PAR
    MESU_PAR
    FOSS_EXI
    FOSS_IMP
    DEGR_EXI
    DEGR_IMP
    PUIT_EXI
    PUIT_IMP
    EPUR_EXI
    EPUR_IMP
    CITE_EXI
    CITE_IMP
    LOTI_DAT
  }
`

export const INNER_FRAGMENT_OFFRMULT_UNION_MATCH = `
  ... on TABLSURFType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLIMMEType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLACTJType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLOPERType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX      }
  ... on TABLSTYLType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLFAITType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLSUSPType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLGENEType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLGEOGType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLNIVEType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLADREType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX      }
  ... on TABLRENOType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLFABRType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLGELOType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLDESTType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLDOSSType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX      }
  ... on TABLCOMIType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLBAILType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLPERFType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLINONType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLACTIType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLINTEType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLEXTEType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLCHAUType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLRETRType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLGESTType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLTERRType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLSTATType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLDISPType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLQUARType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLAFFEType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLSOURType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLRELIType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLTERDType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
  ... on TABLMANDType { LIBE_FR LIBE_EN LIBE_NL LIBE_DE LIBE_XX IWEB }
`